.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo {
  font-size: 4em;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.footer-link {
  font-weight: bold;
  font-size: medium;
  margin-left: 1em;
}

.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.tm {
  font-size: small;
  font-weight: bold;
  color: grey;
}


.footer-container {
 
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}


.subheading {
  margin-top: -1em;
}

.headings {
  margin-top: 4em;
}

.App-header {
  background-color: #1f1c1c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Play-btn {
  color: white;
  background-color: #754287;
  border-radius: 999px;
  font-weight: 600;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 2.5em;
  padding-right: 2.5em;
  text-decoration: none;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
